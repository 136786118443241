


































































import BookCreate from "../components/BookCreate.vue";

import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
	components: {
		BookCreate,
	},
})
export default class Home extends Vue {
	isShowBookCreate: boolean = false;
	searchTimer: number = 0;
	isNotification: boolean = false;
	isProfile: boolean = false;
	search: string = "";
	filteredBooks: any = [];
	books: any = [
		{
			title: "부의 대이동",
			image: "https://m.media-amazon.com/images/I/51d2e+67u1L.jpg",
		},
		{
			title: "보통의 언어들",
			image: "https://m.media-amazon.com/images/I/51d2e+67u1L.jpg",
		},
		{
			title: "인생의 태도",
			image: "https://m.media-amazon.com/images/I/51d2e+67u1L.jpg",
		},
		{
			title: "통찰과 역설1",
			image: "https://m.media-amazon.com/images/I/51d2e+67u1L.jpg",
		},
		{
			title: "통찰과 역설2",
			image: "https://m.media-amazon.com/images/I/51d2e+67u1L.jpg",
		},
		{
			title: "통찰과 역설3",
			image: "https://m.media-amazon.com/images/I/51d2e+67u1L.jpg",
		},
		{
			title: "통찰과 역설4",
			image: "https://m.media-amazon.com/images/I/51d2e+67u1L.jpg",
		},
	];

	@Watch("search")
	isSearchChanged() {
		if (this.searchTimer) {
			clearTimeout(this.searchTimer);
		}
		this.searchTimer = setTimeout(() => {
			// TODO
			this.filteredBooks = this.books.filter(
				(books: any) =>
					books.title
						.toLowerCase()
						.indexOf(this.search.toLowerCase()) != -1
			);
		}, 300);
	}

	get getUserData() {
		return this.$store.state.userData;
	}

	created() {
		if (!this.getUserData.userID) this.$router.push("/login");
		this.filteredBooks = this.books;
	}

	toggleNotification() {
		this.isNotification = !this.isNotification;
	}

	toggleProfile() {
		this.isProfile = !this.isProfile;
	}

	sort() {
		// TODO
		return;
	}
}
