





























































import { Component, Vue } from "vue-property-decorator";

import TextInput from "@/components/TextInput.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import Button from "@/components/Button.vue";

import io from "socket.io-client";
import { Socket } from "vue-socket.io-extended";
import VueSocketIOExt from "vue-socket.io-extended";
const socket = io("https://cotex-api.hyunwoo.dev/");

interface Cell {
  idx: number;
  x: number;
  y: number;
  width: number;
  height: number;
  linkwith: number[];
  text?: string; // 텍스트
  img?: string; // 이미지 경로
}

Vue.use(VueSocketIOExt, socket);

@Component
export default class Page extends Vue {
  gridX: number = 9;
  gridY: number = 13;
  gridWidth: number = 0;
  gridHeight: number = 0;
  width: number = 0;
  height: number = 0;
  offsetLeft: number = 0;
  offsetTop: number = 0;
  cells: Cell[] = [];
  permission: any[] = [];

  box!: HTMLDivElement;

  selectedIndex: number = -1;
  startPositionX: number = 0;
  startPositionY: number = 0;
  startWidth: number = 0;
  startHeight: number = 0;

  startResize: boolean = false;

  createCell() {
    let cell: Cell = {
      idx: this.cells.length > 0 ? this.cells[this.cells.length - 1].idx + 1 : 0,
      x: 0,
      y: 0,
      width: 1,
      height: 1,
      linkwith: [],
      text: "테스트",
    };
    if (cell.idx == 0) this.selectedIndex = 0;
    this.cells.push(cell);
    this.dataUpdate();
  }

  mounted() {
    this.box = this.$refs.box as HTMLDivElement;

    let resize = () => {
      this.width = this.box.getBoundingClientRect().width;
      this.height = this.box.getBoundingClientRect().height;
      this.offsetLeft = this.box.getBoundingClientRect().left;
      this.offsetTop = this.box.getBoundingClientRect().top;

      this.gridWidth = this.width / this.gridX;
      this.gridHeight = this.height / this.gridY;
    };
    window.onresize = resize;
    resize();
  }
  eventStart(e: MouseEvent, idx: number) {
    let cell: Cell | undefined = this.cells.find(cell => cell.idx == idx);

    let x = e.clientX - this.offsetLeft;
    let y = e.clientY - this.offsetTop;

    this.startPositionX = x;
    this.startPositionY = y;
    this.startWidth = cell!.width;
    this.startHeight = cell!.height;

    this.selectedIndex = idx;

    this.startResize = true;
  }
  eventMove(e: MouseEvent) {
    if (this.startResize) {
      let x = e.clientX - this.offsetLeft;
      let y = e.clientY - this.offsetTop;

      let cell: Cell | undefined = this.cells.find(cell => cell.idx == this.selectedIndex);
      if (cell) {
        cell.width = this.startWidth + Math.floor((x - this.startPositionX) / this.gridWidth) + 1;
        cell.height = this.startHeight + Math.floor((y - this.startPositionY) / this.gridHeight) + 1;
      }
    }
  }
  eventEnd(e: MouseEvent) {
    this.startResize = false;
    this.startPositionX = 0;
    this.startPositionY = 0;
    this.dataUpdate();
  }
  dataUpdate() {
    this.$socket.client.emit("update", {
      pageId: "test",
      cell: this.cells,
    });
  }
  get getUser() {
    return this.$store.state.userData;
  }

  @Socket()
  connect() {
    console.log("START");
    this.$socket.client.emit("joinRoom", { pageId: "test" });
  }
  @Socket("joinRoom")
  onJoinRoom() {
    console.log("join clear");
  }
  @Socket("update")
  onUpdate(data: any) {
    this.cells = data.cell;
    this.permission = data.permission;
  }
}
