

































































import { Component, Vue } from "vue-property-decorator";

import Button from "@/components/Button.vue";

@Component({
  components: {
    Button,
  },
})
export default class Login extends Vue {
  isRegister: boolean = false;
  id: string = "";
  password: string = "";

  naverLogin() {
    window.location.href = "https://cotex.hyunwoo.dev/api/auth/user/login/naver";
  }
  kakaoLogin() {
    window.location.href = "https://cotex.hyunwoo.dev/api/auth/user/login/kakao";
  }
  githubLogin() {
    window.location.href = "https://cotex.hyunwoo.dev/api/auth/user/login/github";
  }

  async submit() {
    if (this.id && this.password) {
      if (!this.isRegister) {
        let res = await this.$store.dispatch("LOGIN", {
          userID: this.id,
          password: this.password,
        });
        if (res) {
          this.$router.replace("/");
        } else {
          alert("계정 정보가 일치하지 않습니다.");
        }
      } else {
        let res = await this.$store.dispatch("REGISTER", {
          userID: this.id,
          password: this.password,
        });
        if (res) {
          this.$router.replace("/");
        } else {
          alert("회원가입에 실패하였습니다.");
        }
      }
    }
  }
}
