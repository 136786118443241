












































import { Component, Vue } from "vue-property-decorator";
@Component
export default class BookCreate extends Vue {
	title: string = "";

	get getUser() {
		return this.$store.state.userData;
	}
}
